import Api from "../elements/api";
import Notification from "../elements/notification";
import Device from "./device_manager";
import Storage from '../polyfills/storage'
import Switch from "../elements/switch";
import Helper from "../polyfills/helper";

const NotificationService = (function() {

  // Your web app's Firebase configuration
  const firebaseConfig = {
    apiKey: "AIzaSyB9LJbi1aVpzAKFm6lg-A_jQDM-bZzwwlk",
    authDomain: "buzz-e3304.firebaseapp.com",
    databaseURL: "https://buzz-e3304.firebaseio.com",
    projectId: "buzz-e3304",
    storageBucket: "buzz-e3304.appspot.com",
    messagingSenderId: "1039858984552",
    appId: "1:1039858984552:web:64cc2e75d6eacb2fcd7ddd"
  };

  let version = '1.03';
  const PUSH_TOKEN = 'PUSH_TOKEN';
  const PUSH_TOKEN_SENT = 'PUSH_TOKEN_SENT';

  let registerElements = null;
  let messaging = {};

  function init() {
    checkVersion();

    registerElements = Array.from(document.querySelectorAll('[data-register-push]'));
    if (Helper.isEmpty(registerElements)) { return; }

    if (typeof(firebase) === 'undefined') {
      toggleRegisterElements(false)
      registerElements.forEach(registerElement => {
        registerElement.classList.add('disabled')
      });

      return;
    }

    try {
      firebase.initializeApp(firebaseConfig);
      messaging = firebase.messaging();
      messaging.usePublicVapidKey("BEmonDAXhzfdL-MXrqGDxuv1igTjJLYgsPwpIxS1bBgWoF0g5eSytBB5COCY1cmRMKTKJ7P1siXHQ5tO3vl8PYc")

      hookUpRefreshTokenHandler(messaging);
      hookUpForeGroundMessageRecievedHandler(messaging);

      setRegisterElementsListeners();

      if (!isTokenSendToServer() && isRegistered()) {
        reSendTokenToServer()
      }

      if (isRegistered()) {
        sendPushNotificationEnabledEvent();
      } else {
        sendPushNotificationDisabledEvent();
      }
    } catch(e) {
      // debugger
    }
  }

  function setRegisterElementsListeners() {
    registerElements.forEach(registerElement => {
      registerElement.querySelector('input').removeEventListener('change', registerForPushNotifications);
      registerElement.querySelector('input').removeEventListener('change', clearTokenOnServer);

      if (isRegistered() === false) {
        toggleRegisterElement(registerElement, false)
        registerElement.querySelector('input').addEventListener('change', registerForPushNotifications);
      } else {
        toggleRegisterElement(registerElement, true)
        registerElement.querySelector('input').addEventListener('change', clearTokenOnServer);
      }
    });
  }

  function toggleRegisterElement(registerElement, value) {
    Switch.setSwitch(registerElement, value);
    registerElement.classList.remove('hidden');
  }

  function hookUpRefreshTokenHandler(messaging) {
    messaging.onTokenRefresh(() => {
      messaging.getToken().then((refreshedToken) => {
        sendTokenToServer(refreshedToken);
      }).catch((e) => {
        // Raven.captureException(e);
      });
    });
  }

  function hookUpForeGroundMessageRecievedHandler(messaging) {
    messaging.onMessage((payload) => {
      console.log('Message received. ', payload.notification);
      sendNotificationEvent(payload);
    });
  }

  function sendNotificationEvent(notification) {
    document.dispatchEvent(new CustomEvent("push_notification::received", {
      detail: notification
    }));
  }

  function registerForPushNotifications(){
    messaging.requestPermission().then(function () {
      return messaging.getToken();
    }).then(function (token) {
      console.log(token)
      sendTokenToServer(token);
    }).catch(function (e) {
      // Raven.captureException(e);
      setRegisterElementsListeners()
    });
  }

  function sendTokenToServer(token, destroy = false) {
    const request = new Request('/pwa/devices');
    setRegistered(token);

    Api.callApi(request, 'POST', {
      token: token,
      destroy: destroy,
      device_type: Device.deviceType(),
    })
      .then((result) => {
        if (result.status == '200') {
          setTokenSendToServer();
          setRegisterElementsListeners();
          sendPushNotificationEnabledEvent();
        } else {
          setRegistered('');
          setTokenSendToServer();
          sendPushNotificationDisabledEvent();
          setRegisterElementsListeners()
        }
      })
      .catch(ex => {
        Notification.createToast(ex.message)
        setRegisterElementsListeners();
      });
  }

  function clearTokenOnServer() {
    const token = Storage.getItem(PUSH_TOKEN);
    if (token) {
      sendTokenToServer(token, true)
    } else {

    }
  }

  function reSendTokenToServer() {
    if (isRegistered() === false) { return; }

    const token = Storage.getItem(PUSH_TOKEN);
    if (token) {
      sendTokenToServer(token)
    }
  }

  function sendPushNotificationEnabledEvent() {
    document.dispatchEvent(new CustomEvent("push_notification::enabled", {
      detail: {}
    }));
  }

  function sendPushNotificationDisabledEvent() {
    document.dispatchEvent(new CustomEvent("push_notification::disabled", {
      detail: {}
    }));
  }

  function isRegistered() {
    return !Helper.isEmpty(Storage.getItem(PUSH_TOKEN))
  }

  function setRegistered(token) {
    Storage.setItem(PUSH_TOKEN, token)
  }

  function isTokenSendToServer(){
    return !Helper.isEmpty(Storage.getItem(PUSH_TOKEN_SENT))
  }

  function setTokenSendToServer(){
    Storage.setItem(PUSH_TOKEN_SENT, '1')
  }

  function checkVersion() {
    const storedVersion = Storage.getItem('push_manager_version');
    if (storedVersion !== version) {
      Storage.setItem(PUSH_TOKEN, '');
      Storage.setItem('push_manager_version', version);
    }
  }

  return {
    registerForPushNotifications: registerForPushNotifications,
    sendTokenToServer: sendTokenToServer,
    reSendTokenToServer: reSendTokenToServer,
    init: init
  }
})();

export default NotificationService
