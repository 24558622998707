import * as Sentry from "@sentry/browser";

const Api = (function(){
  const headersHash = {
    'pragma': 'no-cache', //No caching!
    'cache-control': 'no-cache',
    'Content-Type': 'application/json'
  }

  function callApi(request, method, data, _headers = {}) {
    return new Promise((resolve, reject) => {
      Object.assign(headersHash, _headers);

      const initObject = {
        method: method,
        headers: new Headers(headersHash),
        credentials: 'include'
      };

      if (method == "POST" || method == "PATCH" || method == "DELETE") {
        if (headersHash['Content-Type'] == 'application/json') {
          data.authenticity_token = document.head.querySelector('meta[name="csrf-token"]').getAttribute('content');
          initObject.body = JSON.stringify(data);
        } else {
          data.append('authenticity_token', document.head.querySelector('meta[name="csrf-token"]').getAttribute('content'))
          initObject.body = data;
        }
      }

      let status;

      fetch(request, initObject)
        .then(function (response) {
          status = response.status
          const contentType = response.headers.get("content-type");
          if (contentType && contentType.indexOf("application/json") !== -1) {
            return response.json();
          } else {
            return response.text();
          }
        })
        .then(function (data) {
          if (status < 300) {
            resolve(data);
          } else if (data.error) {
            reject(data); // handle error in caller
          } else {
            if (status !== 404 && status !== 422) { // 422 can be caused by not validating authenticity_token (ie. session expired)
              Sentry.captureException(new Error("Status: " + status + " rejecting data"));
            }
            reject(data)
          }
        })
        .catch(function (error) {
          Sentry.captureException(error);
          reject(error)
        });
    });
  }

  return {
    callApi: callApi
  }
})();


export default Api;